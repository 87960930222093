import {SET_LINKS} from '../../actions/app/links/links.actions'

export const linksReducer = (links = {}, action = {}) => {
    const {payload} = action

    if (action.type === SET_LINKS) {
        return payload.data
    } else {
        return links
    }
}
