import React from 'react';
import {Link} from "react-router-dom";
import SelectContractContainer from "../../../../../Commun/SelectContract/SelectContractContainer";
import _ from "lodash";
import APILink from "../../../../../Commun/APILink/APILink.jsx";
import FormReleveInformation
    from "../../Questions/ListeQuestions/Components/ReleveInformation/FormReleveInformation/FormReleveInformation.jsx";

const ListeDocuments = (props) => {
    const { quittance, contract } = props

    return (
        <div>
            <div className={'mt-5 pb-2'}>
                <h1 className={'fw-bold'}><Link to={'/'}><i className="bi bi-arrow-left-circle me-2" /></Link>Mes documents</h1>
            </div>


            <SelectContractContainer>
                {
                    !_.includes(['6', '5', 'I'], contract.situation?.statutCode) && quittance && <>
                        {(quittance?.memo_link && !_.isEmpty(contract.vehicule.immatriculation)) &&
                            <APILink className={"btn btn-primary max-rounded d-block d-lg-inline-block m-3 w-45"}
                                     href={quittance.memo_link}>
                                <i className={'fas fa-file-pdf'}/> Télécharger le Mémo Véhicule Assuré
                            </APILink>
                        }

                        {quittance?.attestation_assurance_link &&
                            <APILink className={"btn btn-primary max-rounded d-block d-lg-inline-block m-3 w-45"}
                                     href={quittance.attestation_assurance_link}>
                                <i className={'fas fa-file-pdf'}/> Télécharger l'Attestation d'assurance
                            </APILink>
                        }

                        {(quittance?.attestation_rc_circuit_link) &&
                            <APILink className={"btn btn-primary max-rounded d-block d-lg-inline-block m-3 w-45"}
                                     href={quittance.attestation_rc_circuit_link}>
                                Télécharger l'attestation RC Roulage Circuit
                            </APILink>
                        }
                    </>
                }

                {
                    contract.ri_link && !_.startsWith(contract.vehicule.codeProduit, '229127_') && <div className={'col-12 col-sm-6 mt-3 px-3'}>
                        <p>Pour télécharger votre <b>Relevé d'Informations</b>, sélectionnez votre contrat, un motif puis cliquez sur le bouton
                            ci-dessous :</p>
                        <FormReleveInformation riLink={contract.ri_link} title={"Editer mon relevé d'informations"}/>
                    </div>
                }
            </SelectContractContainer>
        </div>
    );
};

export default ListeDocuments;
