import React, {useState} from 'react';
import {Formik} from "formik";
import Text from "../../../../../Commun/Inputs/Text/Text";
import Select from "../../../../../Commun/Inputs/Select/Select";
import SimpleDatePicker from "../../../../../Commun/Inputs/DatePicker/SimpleDatePicker";
import {Link} from "react-router-dom";
import SelectContractContainer from "../../../../../Commun/SelectContract/SelectContractContainer";
import Textarea from "../../../../../Commun/Inputs/Textarea/Textarea";
import Files from "../../../../../Commun/Inputs/Files/Files";
import _ from "lodash";
import Warning from "../../Home/Warning/Warning";
import moment from "moment/moment";
import RadioGroup from "../../../../../Commun/Inputs/RadioGroup/RadioGroup";
import {getMotif} from "../../../../../../utils/getMotif";
import Swal from "sweetalert2";

const FormulaireResil = (props) => {
    const { isVAE, isNVEI, initialValues, situation, echeance, dateAfn, titre, usage, dateAvt, garanties, accidents, postTerminate, queryTerminate, dateEcheance } = props

    const [requiredFile, setRequiredFile] = useState(false)
    const [msgInfo, setMsgInfo] = useState('')
    const [showRecontact, setShowRecontact] = useState(false)
    const [showRecap, setShowRecap] = useState(false)
    const [buttonDisabled, setButtonDisabled] = useState(false);

    const diffDaysAfnToday = moment().diff(dateAfn, 'days')
    const diffDaysAvtToday = moment().diff(dateAvt, 'days')
    const diffEcheanceToday = moment().diff(dateEcheance, 'days')

    const handleClickNextStep = (values) => {
        if (values.motif === 'A' && values.files['B'] === undefined){
            Swal.fire({
                title: 'Attention',
                text: 'Merci de nous transmettre le certificat de cession ou la carte barrée et signée.',
                showConfirmButton: true,
                confirmButtonText: 'Terminer',
                showCloseButton: true
            })
        } else if(values.motif === 'vol' && values.files['B'] === undefined) {
            Swal.fire({
                title: 'Attention',
                text: 'Merci de nous transmettre le procès-verbal de vol. Si vous ne l\'avez pas, nous vous invitons à déposer une plainte auprès des forces de l\'ordre',
                showConfirmButton: true,
                confirmButtonText: 'Terminer',
                showCloseButton: true
            })
        } else if(values.motif === 'situation' && !values.commentaire) {
            Swal.fire({
                title: 'Attention',
                text: 'Merci de préciser votre situation en commentaire',
                showConfirmButton: true,
                confirmButtonText: 'Terminer',
                showCloseButton: true
            })
        } else if(values.motif === 'autre' && !values.commentaire) {
            Swal.fire({
                title: 'Attention',
                text: 'Merci de préciser votre demande en commentaire',
                showConfirmButton: true,
                confirmButtonText: 'Terminer',
                showCloseButton: true
            })
        } else if (values.motif === 'A' && !values.susp_res) {
            Swal.fire({
                title: 'Attention',
                text: 'Merci de préciser si vous souhaitez suspendre ou résilier votre contrat',
                showConfirmButton: true,
                confirmButtonText: 'Terminer',
                showCloseButton: true
            })
        } else {
            setShowRecap(true)
            window.scroll(0,0)
        }
    }

    const onChangeMotifResil = (setFieldValue, value) => {
        setMsgInfo('')
        setFieldValue('motif', value);
        setRequiredFile(false);
        setShowRecontact(false);
        setFieldValue('date_evenement', undefined);

        const setCommonFields = (action, motif_refus = null) => {
            setFieldValue('action', action);
            if (motif_refus) {
                setFieldValue('motif_refus', motif_refus);
            }
        };

        switch (value) {
            case 'echeance':
                setShowRecontact(true);
                if (diffEcheanceToday > 335 || (diffEcheanceToday <= -90 && diffEcheanceToday >= -335)) {
                    setCommonFields('ag_res');
                    setFieldValue('date_ag_res', moment(dateEcheance).subtract(60, 'days').format('YYYY-MM-DD'));
                } else if (diffEcheanceToday <= -60 && diffEcheanceToday >= -90) {
                    setCommonFields('terminate');
                    setFieldValue('date_evenement', moment(dateEcheance).subtract(1, 'days').format('YYYY-MM-DD'));
                    setFieldValue('motif', 'B')
                } else if (diffEcheanceToday <= 0 && diffEcheanceToday >= -60) {
                    setCommonFields('create_res');
                } else if (diffEcheanceToday <= 30) {
                    setShowRecontact(false);
                    setCommonFields('create_res');
                } else {
                    setCommonFields('create_res_esp');
                }
                break;
            case 'Q':
                // Résiliation Rétractation souscription
                if (diffDaysAfnToday <= 14) {
                    setFieldValue('date_evenement', moment().format('YYYY-MM-DD'));
                    setCommonFields('terminate');
                } else {
                    setCommonFields('send_mail_refus', 'Q.1');
                }
                break;
            case '0':
                // Résiliation loi hamon
                setShowRecontact(true);

                let motif_refus;
                if (diffDaysAfnToday < 365) {
                    motif_refus = '0.1';
                } else if (titre === 'SOC' || usage === 'MA - Affaires') {
                    motif_refus = '0.2';
                } else {
                    motif_refus = '0.3';
                }

                setCommonFields('send_mail_refus', motif_refus);
                break;
            case 'A':
                // Résiliation suite à vente
                setFieldValue('action', 'create_res_vente');
                setRequiredFile(true);
                break;
            case '3':
                // Résiliation suite refus d'avenant
                if (diffDaysAvtToday <= 30) {
                    setFieldValue('date_evenement', moment().add(10, 'days').format('YYYY-MM-DD'));
                    setCommonFields('terminate');
                } else {
                    setCommonFields('send_mail_refus', '3.1');
                }
                break;
            case 'prime':
                setShowRecontact(true);
                setCommonFields('create_res_esp');
                break;
            case 'situation':
                // Résiliation changement de situation
                setRequiredFile(true);
                setCommonFields('create_res_esp');
                break;
            case 'vol':
                // Résiliation vol
                if (!_.find(garanties, ['code_famille', 'VI'])) {
                    setFieldValue('action', 'create_res_esp');
                    setRequiredFile(true);
                } else {
                    setRequiredFile(false);
                    if(!_.isEmpty(accidents)) {
                        _.map(accidents, function (accident) {
                            if (_.includes(accident, ['categorieCode', ['10.0', '105.1', '9.0', '105.2', '18.0', '19.0']])) {
                                setMsgInfo('Vous devez dans un premier temps déclarer le vol sur votre espace assuré, puis votre contrat sera automatiquement suspendu 30 jours après le vol.');
                            } else {
                                setMsgInfo('Suite à la déclaration pour vol, votre contrat sera suspendu automatiquement 30 jours après le vol.');
                            }
                        });
                    } else {
                        setMsgInfo('Vous devez dans un premier temps déclarer le vol sur votre espace assuré, puis votre contrat sera automatiquement suspendu 30 jours après le vol.');
                    }
                }
                break;
            case 'X':
                // NON ACHAT
                if (situation === '2' && diffDaysAfnToday < 30) {
                    setFieldValue('action', 'terminate');
                    setFieldValue('date_evenement', moment(dateAfn).format('YYYY-MM-DD'));
                }
                break;
            case 'autre':
                // Résiliation autre
                setRequiredFile(true);
                setCommonFields('create_res_esp');
                break;
            default:
                break;
        }
    }

    return (
        <div>
            <div className={'mt-5 pb-2'}>
                <h1 className={'fw-bold'}><Link to={'/'}><i className="bi bi-arrow-left-circle me-2" /></Link>Résilier</h1>
            </div>

            {!_.includes(['6', '5', '9'], situation)&&<div className={'bloc-white bloc-info col-12'}>
                <p className={'m-0 pe-5'}>Avant de résilier votre contrat, prenons le temps d'échanger au <strong>02 47 51 07 07</strong> pour trouver ensemble une solution adaptée à votre situation.<br/><small>(Appel non surtaxé du lundi au vendredi de 9h à 18h)</small></p>
            </div>}

            <SelectContractContainer>
                {_.includes(['6', '5', '9', 'I'], situation) ?
                    <Warning messageWarning={'Votre contrat est déjà résilié ou suspendu.'}/> :
                    <Formik initialValues={initialValues}
                            onSubmit={(values, { resetForm }) => {
                                setButtonDisabled(true);
                                postTerminate({
                                    query: queryTerminate,
                                    form: values,
                                    successFunction: () => {
                                        props.history.push(`/`);
                                        resetForm();
                                    }
                                })
                            }}
                    >
                        {({handleSubmit, errors, touched, handleChange, handleBlur, values, setFieldValue}) => (
                            <form onSubmit={handleSubmit}>
                                {!showRecap && <div className={'formulaire'}>
                                    <div className={'mb-4 mt-4'}>
                                        <Text
                                            type={'text'}
                                            name="police"
                                            label={'Numéro d’adhésion'}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.police}
                                            setFieldValue={setFieldValue}
                                            disabled
                                        />
                                    </div>
                                    <div className={'mb-4 mt-4'}>
                                        <Text
                                            type={'text'}
                                            name="nom"
                                            label={'Nom du souscripteur'}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.nom}
                                            setFieldValue={setFieldValue}
                                            disabled
                                        />
                                    </div>
                                    {
                                        values.prenom && <div className={'mb-4 mt-4'}>
                                            <Text
                                                type={'text'}
                                                name="prenom"
                                                label={'Prénom du souscripteur'}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.prenom}
                                                setFieldValue={setFieldValue}
                                                disabled
                                            />
                                        </div>
                                    }
                                    <div className={'mb-4 mt-4'}>
                                        <Text
                                            type={'text'}
                                            name="email"
                                            label={'Adresse email de contact'}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.email}
                                            setFieldValue={setFieldValue}
                                        />
                                    </div>
                                    <div className={'mb-4 mt-4'}>
                                        <Select
                                            required
                                            name={"motif"}
                                            value={values.motif}
                                            error={errors.motif}
                                            label={'Quel est le motif de votre résiliation ?'}
                                            onChange={e => {
                                                onChangeMotifResil(setFieldValue, e.target.value)
                                            }}>
                                            <option value={''}>Sélectionnez votre motif</option>
                                            <option value={'Q'}>Résiliation rétractation 14 jours</option>
                                            <option value={'vol'}>Résiliation pour vol de véhicule</option>
                                            {situation === '2' && <option value={'X'}>Résiliation pour non achat</option>}
                                            <option value={'A'}>Résiliation suite à vente</option>
                                            {situation !== '2' && <>
                                                <option value={'echeance'}>Résiliation à échéance / loi Chatel</option>
                                                { !isVAE && !isNVEI && <option value={'0'}>Résiliation Loi Hamon</option> }
                                                <option value={'3'}>Résiliation suite à un refus d’avenant</option>
                                                <option value={'prime'}>Résiliation pour augmentation de prime</option>
                                                <option value={'situation'}>Résiliation pour changement de situation
                                                    (adresse, profession...)
                                                </option>
                                                <option value={'autre'}>Résiliation pour un autre motif</option>
                                            </>
                                            }
                                        </Select>
                                    </div>
                                    {
                                        values.date_evenement && <>
                                            {values.motif === '3' &&
                                                <div className="alert alert-info mt-5" role="alert">
                                                    Lors d'un refus d'avenant, la résiliation est effective 10 jours la
                                                    demande.
                                                </div>
                                            }
                                            <div className={'mb-4 mt-4'}>
                                                <SimpleDatePicker
                                                    label={'Date de l\'évènement donnant lieu à la résiliation'}
                                                    name={'date_evenement'}
                                                    setFieldValue={setFieldValue}
                                                    value={values.date_evenement}
                                                    error={errors.date_evenement}
                                                    required
                                                    disabled
                                                />
                                            </div>
                                        </>
                                    }

                                    {
                                        values.motif === 'A' &&
                                        <RadioGroup
                                            label={"Souhaitez vous suspendre ou résilier votre contrat ?"}
                                            name={`susp_res`}
                                            setFieldValue={setFieldValue}
                                            options={[
                                                {value: "suspension", label: "Suspendre"},
                                                {value: "resiliation", label: "Résilier"},
                                            ]}
                                            value={values.susp_res}
                                            required={values.motif === 'A'}
                                        />
                                    }

                                    {
                                        showRecontact &&
                                        <RadioGroup
                                            label={values.motif === '0' ? "Vous voulez nous quitter ? Et si nous prenions 5 minutes pour revoir ensemble votre contrat ?" : values.motif === 'prime' ? "Votre contrat est-il toujours adapté ? Nous pouvons revoir vos besoins ensemble" : "Il est encore temps de revoir votre contrat avant la prochaine échéance, et si nous en discutions ? "}
                                            name={`recontact`}
                                            setFieldValue={setFieldValue}
                                            options={[
                                                {value: "1", label: "Oui"},
                                                {value: "0", label: "Non"},
                                            ]}
                                            value={values.recontact}
                                        />
                                    }

                                    {(values.motif === 'situation' || values.motif === 'autre') && <Textarea
                                        label={'Commentaire'}
                                        name={"commentaire"}
                                        value={values.commentaire}
                                        error={errors.commentaire}
                                        maxLength={255}
                                    />}

                                    {requiredFile && <>
                                        {values.motif === 'vol' &&
                                            <div className="alert alert-info mt-5" role="alert">
                                                Merci de nous transmettre le procès-verbal de vol. Si vous ne l'avez pas, nous vous invitons à déposer une plainte auprès des forces de l'ordre.
                                            </div>
                                        }
                                        {values.motif === 'A' &&
                                            <div className="alert alert-info mt-5" role="alert">
                                                Merci de nous transmettre le certificat de cession ou la carte barrée et signée.
                                            </div>
                                        }
                                        <Files
                                            label={'Pièce justificative'}
                                            name={`files[B]`}
                                            arrayName={'files'}
                                            value={values.files}
                                            setFieldValue={setFieldValue}
                                            code={'B'}
                                            accept={'image/*,application/pdf'}
                                        />
                                    </>
                                    }

                                    {msgInfo ? <Warning messageWarning={msgInfo}/> :
                                        <div className="d-flex my-5 align-items-center justify-content-between">
                                            <span className={`btn btn-primary w-100`}
                                                  onClick={() => handleClickNextStep(values)}>
                                                Je valide
                                            </span>
                                        </div>
                                    }
                                </div>
                                }
                                { showRecap && <div className={'recap'}>
                                    <div className={'bloc-white'}>
                                        <div>
                                            <h4 className={'fw-bold text-primary-light d-inline-block'}>Récapitulatif de votre demande</h4>
                                        </div>
                                        <div className={'mt-4'}>
                                            <div className="row">
                                                <div className="col">
                                                    <p className={'mb-1 fw-bold'}>Nom et prénom</p>
                                                    <p className={'m-0 text-secondary'}>{values.nom} {values.prenom}</p>
                                                </div>
                                                <div className="col">
                                                    <p className={'mb-1 fw-bold'}>Numéro d’adhésion</p>
                                                    <p className={'m-0 text-secondary'}>{values.police}</p>
                                                </div>
                                            </div>

                                            <hr/>

                                            <div className="row">
                                                <div className="col">
                                                    <p className={'mb-1 fw-bold'}>Motif de votre résiliation</p>
                                                    <p className={'m-0 text-secondary'}>{getMotif(values.motif)}</p>
                                                </div>
                                                {values.date_evenement &&
                                                    <div className="col">
                                                        <p className={'mb-1 fw-bold'}>Date d'effet de votre résiliation</p>
                                                        <p className={'m-0 text-secondary'}>{moment(values.date_evenement).format('L')}</p>
                                                    </div>
                                                }
                                            </div>
                                            { values.commentaire &&
                                                <div className="row">
                                                    <div className="col">
                                                        <p className={'mb-1 fw-bold'}>Commentaire</p>
                                                        <p className={'m-0 text-secondary'}>{values.commentaire}</p>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <hr/>
                                        <div className={'d-flex flex-column flex-md-row'}>
                                            <button disabled={buttonDisabled} type={'submit'} className={'btn btn-primary col-lg-6 col-12'}>{values.recontact === "1" ? 'Confirmer ma demande de rappel' : 'Confirmer ma demande de résiliation'}</button>
                                            <span className={`ms-lg-4 btn btn-secondary col-lg-6 col-12 my-2 my-lg-0`} onClick={() => setShowRecap(false)}>
                                                Annuler
                                            </span>
                                        </div>
                                    </div>
                                </div> }
                            </form>
                        )}
                    </Formik>
                }
            </SelectContractContainer>
        </div>
    );
};

export default FormulaireResil;
