import {CUSTOMER, POST_CUSTOMER_UPDATE_PASSWORD} from '../../../../actions/app/customer/customer.actions'
import {API_ERROR, API_SUCCESS, apiRequest} from '../../../../actions/core/api/api.actions'
import {setError, setLoader} from '../../../../actions/app/ui/ui.actions'
import {setNotification} from '../../../../actions/core/notifications/notifications.actions'
export const customerUpdatepasswordMiddleware = () => next => action => {
    next(action)

    const { payload } = action

    switch (action.type){

        case POST_CUSTOMER_UPDATE_PASSWORD:
            next([
                apiRequest({body: payload.body, method: 'POST', url: payload.data, entity: POST_CUSTOMER_UPDATE_PASSWORD, otherData: payload.otherData}),
                setLoader({state: true, entity: CUSTOMER})
            ])
            break

        case `${POST_CUSTOMER_UPDATE_PASSWORD} ${API_SUCCESS}`:
            next([
                setError({state: false, entity: POST_CUSTOMER_UPDATE_PASSWORD}),
                setLoader({state: false, entity: POST_CUSTOMER_UPDATE_PASSWORD}),
                setNotification({
                    entity: POST_CUSTOMER_UPDATE_PASSWORD,
                    html: '<div><p>Votre demande a bien été prise en compte.</p></div>',
                    type: 'success',
                    title: 'Félicitations !',
                    confirmButtonText: 'J\'ai compris',
                    successFunction: payload.meta.otherData.successFunction
                })
            ])
            break

        case `${POST_CUSTOMER_UPDATE_PASSWORD} ${API_ERROR}`:
            next([
                setError({state: true, entity: POST_CUSTOMER_UPDATE_PASSWORD}),
                setLoader({state: false, entity: POST_CUSTOMER_UPDATE_PASSWORD}),
                setNotification({
                    entity: POST_CUSTOMER_UPDATE_PASSWORD,
                    html: '<p>Une erreur est survenue : ' + payload.data.response.data?.message ?? '' + '</p>',
                    icon: 'error',
                    title: 'Attention !',
                    confirmButtonText: 'J\'ai compris'
                })
            ])
            break

        default:
            break
    }
    return null
}
