import {SET_CAS_IDA} from "../../actions/app/cas_ida/cas_ida.actions";

export const casIdaReducer = (cas_ida = {}, action = {}) => {
    const {payload} = action

    if (action.type === SET_CAS_IDA) {
        return payload
    } else {
        return cas_ida;
    }
}
