
import _ from 'lodash'
import {
    POST_AMENDMENT_CHANGE_ADDRESS,
    POST_AMENDMENT_CHANGE_CONTACT,
    POST_AMENDMENT_CHANGE_FRACTIONNEMENT_PRELEVEMENT,
    POST_AMENDMENT_CHANGE_IBAN, POST_AMENDMENT_FIN_HIVERNAGE, POST_AMENDMENT_GET_HIVERNAGE_REDUC,
    POST_AMENDMENT_HIVERNAGE
} from "../../../actions/app/amendment/amendment.actions";
import {API_ERROR, API_SUCCESS, apiRequest} from "../../../actions/core/api/api.actions";
import {setError, setHivernageReduction, setLoader} from "../../../actions/app/ui/ui.actions";
import {setNotification} from "../../../actions/core/notifications/notifications.actions";
import moment from "moment";

export const amendmentMiddleware = () => next => action => {
    next(action)

    const { payload } = action
    let message='';
    let clone;

    switch (action.type){

        case POST_AMENDMENT_CHANGE_ADDRESS:
            clone = _.cloneDeep(payload.body)

            if(!clone.sansStationnement) {
                if (clone.new_adress_is_new_lieuStationnement) {
                    clone.villeB = clone.villeNew
                    clone.codePostalB = clone.codePostalNew
                } else {
                    clone.villeB = clone.ville_new_lieuStationnement
                    clone.codePostalB = clone.cp_new_lieuStationnement
                }
            }

            next([
                apiRequest({body: (clone), method: 'POST', url: payload.data, entity: POST_AMENDMENT_CHANGE_ADDRESS, otherData: payload.otherData}),
                setLoader({state: true, entity: POST_AMENDMENT_CHANGE_ADDRESS})
            ])
            break

        case `${POST_AMENDMENT_CHANGE_ADDRESS} ${API_SUCCESS}`:
            message='';
            if(payload.meta.otherData.changementTitulaire){
                message = "<p>Votre demande de changement d'adresse a bien été enregistré.</p>";
            }

            next([
                setError({state: false, entity: POST_AMENDMENT_CHANGE_ADDRESS}),
                setLoader({state: false, entity: POST_AMENDMENT_CHANGE_ADDRESS}),
                setNotification({
                    entity: POST_AMENDMENT_CHANGE_ADDRESS,
                    html: '<div>' + message + '<p>Vos informations seront mises à jour sous 24h</p></div>',
                    icon: 'success',
                    title: 'Félicitations !',
                    confirmButtonText: 'J\'ai compris',
                    successFunction: payload.meta.otherData.successFunction
                })
            ])

            break

        case `${POST_AMENDMENT_CHANGE_ADDRESS} ${API_ERROR}`:
            next([
                setError({state: true, entity: POST_AMENDMENT_CHANGE_ADDRESS}),
                setLoader({state: false, entity: POST_AMENDMENT_CHANGE_ADDRESS}),
                setNotification({
                    entity: POST_AMENDMENT_CHANGE_ADDRESS,
                    html: '<p>Une erreur est survenue : ' + payload.data.response.data?.message ?? '' + '</p>',
                    icon: 'error',
                    title: 'Attention !',
                    confirmButtonText: 'J\'ai compris'
                })
            ])
            break

        case POST_AMENDMENT_CHANGE_CONTACT:
            next([
                apiRequest({body: (payload.body), method: 'POST', url: payload.data, entity: POST_AMENDMENT_CHANGE_CONTACT, otherData: payload.otherData}),
                setLoader({state: true, entity: POST_AMENDMENT_CHANGE_CONTACT})
            ])
            break

        case `${POST_AMENDMENT_CHANGE_CONTACT} ${API_SUCCESS}`:
            next([
                setError({state: false, entity: POST_AMENDMENT_CHANGE_CONTACT}),
                setLoader({state: false, entity: POST_AMENDMENT_CHANGE_CONTACT}),
                setNotification({
                    entity: POST_AMENDMENT_CHANGE_CONTACT,
                    html: '<p>Vos informations seront mises à jour sous 24h</p>',
                    type: 'success',
                    title: 'Félicitations !',
                    confirmButtonText: 'J\'ai compris',
                    successFunction: payload.meta.otherData.successFunction
                })
            ])
            break

        case `${POST_AMENDMENT_CHANGE_CONTACT} ${API_ERROR}`:
            next([
                setError({state: true, entity: POST_AMENDMENT_CHANGE_CONTACT}),
                setLoader({state: false, entity: POST_AMENDMENT_CHANGE_CONTACT}),
                setNotification({
                    entity: POST_AMENDMENT_CHANGE_ADDRESS,
                    html: '<p>Une erreur est survenue : ' + payload.data.response.data?.message ?? '' + '</p>',
                    icon: 'error',
                    title: 'Attention !',
                    confirmButtonText: 'J\'ai compris'
                })
            ])
            break

        case POST_AMENDMENT_CHANGE_IBAN:
            clone = _.cloneDeep(payload.body)
            clone.iban = clone.iban.replace(/[-]/g, '')

            if(clone.type === 'P' && clone.birthdate && moment(clone.birthdate).isValid()){
                clone.birthdate = moment(clone.birthdate).format('YYYY-MM-DD')
            }

            next([
                apiRequest({body: (clone), method: 'POST', url: payload.data, entity: POST_AMENDMENT_CHANGE_IBAN, otherData: payload.otherData}),
                setLoader({state: true, entity: POST_AMENDMENT_CHANGE_IBAN})
            ])
            break

        case `${POST_AMENDMENT_CHANGE_IBAN} ${API_SUCCESS}`:
            message='';
            if(payload.meta.otherData.changementTitulaire){
                message = "<p>Votre demande de changement d'adresse a bien été enregistré.</p>";
            }

            next([
                setError({state: false, entity: POST_AMENDMENT_CHANGE_IBAN}),
                setLoader({state: false, entity: POST_AMENDMENT_CHANGE_IBAN}),
                setNotification({
                    entity: POST_AMENDMENT_CHANGE_IBAN,
                    html: '<div>' + message + '<p>Vos informations seront mises à jour sous 24h</p></div>',
                    type: 'success',
                    title: 'Félicitations !',
                    confirmButtonText: 'J\'ai compris',
                    successFunction: payload.meta.otherData.successFunction
                })
            ])
            break

        case `${POST_AMENDMENT_CHANGE_IBAN} ${API_ERROR}`:
            next([
                setError({state: true, entity: POST_AMENDMENT_CHANGE_IBAN}),
                setLoader({state: false, entity: POST_AMENDMENT_CHANGE_IBAN}),
                setNotification({
                    entity: POST_AMENDMENT_CHANGE_IBAN,
                    html: '<p>Une erreur est survenue : ' + payload.data.response.data?.message ?? '' + '</p>',
                    icon: 'error',
                    title: 'Attention !',
                    confirmButtonText: 'J\'ai compris'
                })
            ])
            break

        case POST_AMENDMENT_CHANGE_FRACTIONNEMENT_PRELEVEMENT:
            let data = new FormData()
            clone = _.cloneDeep(payload.body)
            if (clone.iban){
                clone.iban = clone.iban.replace(/[-]/g, '')
            } else {
                delete clone.iban
            }

            Object.keys(clone).forEach(key => {
                if (key === 'files') {
                    data.append('files[G]', clone[key])
                } else {
                    data.append(key, clone[key])
                }
            })

            next([
                apiRequest({body: data, method: 'POST', url: payload.data, entity: POST_AMENDMENT_CHANGE_FRACTIONNEMENT_PRELEVEMENT, otherData: payload.otherData}),
                setLoader({state: true, entity: POST_AMENDMENT_CHANGE_FRACTIONNEMENT_PRELEVEMENT})
            ])
            break

        case `${POST_AMENDMENT_CHANGE_FRACTIONNEMENT_PRELEVEMENT} ${API_SUCCESS}`:
            message = "Vos informations seront mises à jour sous 24h. <br/>Pour finaliser votre demande, veuillez signer électroniquement le mandat SEPA qui va vous être envoyé par email.";

            if (payload.meta.otherData.createChrono){
                message = "Vos informations seront mises à jour sous 24h. <br/> Un email d\'information vient de vous être envoyé."
            }

            next([
                setError({state: false, entity: POST_AMENDMENT_CHANGE_FRACTIONNEMENT_PRELEVEMENT}),
                setLoader({state: false, entity: POST_AMENDMENT_CHANGE_FRACTIONNEMENT_PRELEVEMENT}),
                setNotification({
                    entity: POST_AMENDMENT_CHANGE_FRACTIONNEMENT_PRELEVEMENT,
                    html: message,
                    type: 'success',
                    title: 'Félicitations !',
                    confirmButtonText: 'J\'ai compris',
                    successFunction: payload.meta.otherData.successFunction
                })
            ])
            break

        case `${POST_AMENDMENT_CHANGE_FRACTIONNEMENT_PRELEVEMENT} ${API_ERROR}`:
            next([
                setError({state: true, entity: POST_AMENDMENT_CHANGE_FRACTIONNEMENT_PRELEVEMENT}),
                setLoader({state: false, entity: POST_AMENDMENT_CHANGE_FRACTIONNEMENT_PRELEVEMENT}),
                setNotification({
                    entity: POST_AMENDMENT_CHANGE_FRACTIONNEMENT_PRELEVEMENT,
                    html: '<p>Une erreur est survenue : ' + payload.data.response.data?.message ?? '' + '</p>',
                    icon: 'error',
                    title: 'Attention !',
                    confirmButtonText: 'J\'ai compris'
                })
            ])
            break
        case POST_AMENDMENT_HIVERNAGE:
            clone = _.cloneDeep(payload.body);

            if(clone.dateFin){
                clone.dateFin = moment(clone.dateFin).format('YYYY-MM-DD')
            }

            next([
                apiRequest({body: (clone), method: 'POST', url: payload.data, entity: POST_AMENDMENT_HIVERNAGE, otherData: payload.otherData}),
                setLoader({state: true, entity: POST_AMENDMENT_HIVERNAGE})
            ])
            break

        case `${POST_AMENDMENT_HIVERNAGE} ${API_SUCCESS}`:
            message = "Votre demande a été enregistrée.<br/>Pour la valider, veuillez signer électroniquement la lettre avenant qui va vous être envoyée par e-mail";

            next([
                setError({state: false, entity: POST_AMENDMENT_HIVERNAGE}),
                setLoader({state: false, entity: POST_AMENDMENT_HIVERNAGE}),
                setNotification({
                    entity: POST_AMENDMENT_HIVERNAGE,
                    html: message,
                    type: 'success',
                    title: 'Félicitations !',
                    confirmButtonText: 'J\'ai compris',
                    successFunction: payload.meta.otherData.successFunction
                })
            ])
            break

        case `${POST_AMENDMENT_HIVERNAGE} ${API_ERROR}`:
            next([
                setError({state: true, entity: POST_AMENDMENT_HIVERNAGE}),
                setLoader({state: false, entity: POST_AMENDMENT_HIVERNAGE}),
                setNotification({
                    entity: POST_AMENDMENT_HIVERNAGE,
                    html: '<p>Une erreur est survenue.</p>',
                    icon: 'error',
                    title: 'Attention !',
                    confirmButtonText: 'J\'ai compris'
                })
            ])
            break

        case POST_AMENDMENT_FIN_HIVERNAGE:
            next([
                apiRequest({
                    body: (action.payload.body),
                    method: 'POST',
                    url: action.payload.data,
                    entity: POST_AMENDMENT_FIN_HIVERNAGE
                }),
                setLoader({state: true, entity: POST_AMENDMENT_FIN_HIVERNAGE})
            ])
            break

        case `${POST_AMENDMENT_FIN_HIVERNAGE} ${API_SUCCESS}`:
            next([
                setLoader({state: false, entity: POST_AMENDMENT_FIN_HIVERNAGE}),
                setError({state: false, entity: POST_AMENDMENT_FIN_HIVERNAGE}),
                setNotification({
                    entity: POST_AMENDMENT_FIN_HIVERNAGE,
                    icon: 'success',
                    body: 'Ma période hivernale est bien désactivée',
                    confirmButtonText: 'J\'ai compris'
                })
            ])
            break

        case `${POST_AMENDMENT_FIN_HIVERNAGE} ${API_ERROR}`:
            if (payload.data?.response?.data?.message) {
                message = payload.data.response.data?.message ?? '';
            } else {
                message = 'Une erreur est survenue : ';
            }
            next([
                setError({state: true, entity: POST_AMENDMENT_FIN_HIVERNAGE}),
                setLoader({state: false, entity: POST_AMENDMENT_FIN_HIVERNAGE}),
                setNotification({
                    entity: POST_AMENDMENT_FIN_HIVERNAGE,
                    html: `<p>${message}</p>`,
                    icon: 'error',
                    confirmButtonText: 'J\'ai compris'
                })
            ])
            break

        case POST_AMENDMENT_GET_HIVERNAGE_REDUC:
            clone = _.cloneDeep(payload.body);
            clone.getTarif = 1;

            if(clone.dateFin){
                clone.dateFin = moment(clone.dateFin).format('YYYY-MM-DD')
            }

            next([
                apiRequest({body: (clone), method: 'POST', url: payload.data, entity: POST_AMENDMENT_GET_HIVERNAGE_REDUC, otherData: payload.otherData}),
                setLoader({state: true, entity: POST_AMENDMENT_GET_HIVERNAGE_REDUC})
            ])
            break

        case `${POST_AMENDMENT_GET_HIVERNAGE_REDUC} ${API_SUCCESS}`:
            next([
                setHivernageReduction({state: payload.data?.data?.reduction}),
                setError({state: false, entity: POST_AMENDMENT_GET_HIVERNAGE_REDUC}),
                setLoader({state: false, entity: POST_AMENDMENT_GET_HIVERNAGE_REDUC})
            ])
            break

        case `${POST_AMENDMENT_GET_HIVERNAGE_REDUC} ${API_ERROR}`:
            next([
                setError({state: true, entity: POST_AMENDMENT_GET_HIVERNAGE_REDUC}),
                setLoader({state: false, entity: POST_AMENDMENT_GET_HIVERNAGE_REDUC}),
                setNotification({
                    entity: POST_AMENDMENT_GET_HIVERNAGE_REDUC,
                    html: '<p>Une erreur est survenue.</p>',
                    icon: 'error',
                    title: 'Attention !',
                    confirmButtonText: 'J\'ai compris'
                })
            ])
            break
        default:
            break
    }
    return null
}
