import {SET_FLOTTE} from '../../actions/app/flotte/flotte.actions'

export const flotteReducer = (flotte = {}, action = {}) => {
    const {payload} = action

    if (action.type === SET_FLOTTE) {
        return payload
    } else {
        return flotte
    }
}
