import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setLoader} from '../../../actions/app/ui/ui.actions'
import {POST_COMMUNICATION_PREFERENCES, POST_DEMAT_STATUT} from "../../../actions/app/parameters/parameters.actions";
import {setNotification} from "../../../actions/core/notifications/notifications.actions";
import {POST_CUSTOMER_FORGOT_PASSWORD} from "../../../actions/app/customer/customer.actions";


export const parametersMiddleware = () => next => action => {
    next(action)

    switch (action.type){
        case POST_COMMUNICATION_PREFERENCES:
            next([
                apiRequest({body: (action.payload.body), method: 'POST', url: action.payload.data, entity: POST_COMMUNICATION_PREFERENCES, otherData: action.payload.otherData}),
                setLoader({state: true, entity: POST_COMMUNICATION_PREFERENCES})
            ])
            break

        case `${POST_COMMUNICATION_PREFERENCES} ${API_SUCCESS}`:
            next([
                setError({state: false, entity: POST_COMMUNICATION_PREFERENCES}),
                setLoader({state: false, entity: POST_COMMUNICATION_PREFERENCES}),
                setNotification({
                    entity: POST_CUSTOMER_FORGOT_PASSWORD,
                    html: '<div><p>Votre demande de modification a bien été prise en compte</p></div>',
                    icon: 'success',
                    title: 'Félicitations !',
                    confirmButtonText: 'J\'ai compris',
                    successFunction: action.payload.meta.otherData.successFunction
                }),
            ])
            break

        case `${POST_COMMUNICATION_PREFERENCES} ${API_ERROR}`:
            next([
                setError({state: true, entity: POST_COMMUNICATION_PREFERENCES}),
                setLoader({state: false, entity: POST_COMMUNICATION_PREFERENCES}),
                setNotification({
                    entity: POST_COMMUNICATION_PREFERENCES,
                    html: '<p>Une erreur est survenue : ' + action.payload.data.response.data?.message ?? '' + '</p>',
                    icon: 'error',
                    title: 'Attention !',
                    confirmButtonText: 'J\'ai compris'
                })
            ])
            break

        case POST_DEMAT_STATUT:
            next([
                apiRequest({
                    body: (action.payload.body),
                    method: 'POST',
                    url: action.payload.data,
                    entity: POST_DEMAT_STATUT,
                    otherData: action.payload.otherData
                }),
                setLoader({state: true, entity: POST_DEMAT_STATUT})
            ])
            break

        case `${POST_DEMAT_STATUT} ${API_SUCCESS}`:
            next([
                setError({state: false, entity: POST_DEMAT_STATUT}),
                setLoader({state: false, entity: POST_DEMAT_STATUT}),
                setNotification({
                    entity: POST_DEMAT_STATUT,
                    html: '<div><p>Votre demande de modification a bien été prise en compte</p></div>',
                    icon: 'success',
                    title: 'Félicitations !',
                    confirmButtonText: 'J\'ai compris',
                    successFunction: action.payload.meta.otherData.successFunction
                }),
            ])
            break

        case `${POST_DEMAT_STATUT} ${API_ERROR}`:
            next([
                setError({state: true, entity: POST_DEMAT_STATUT}),
                setLoader({state: false, entity: POST_DEMAT_STATUT}),
                setNotification({
                    entity: POST_DEMAT_STATUT,
                    html: '<p>Une erreur est survenue : ' + action.payload.data.response.data?.message ?? '' + '</p>',
                    icon: 'error',
                    title: 'Attention !',
                    confirmButtonText: 'J\'ai compris'
                })
            ])
            break

        default:
            break
    }
    return null
}
