export const getCategoryByTypeAccident = type_sinistre => {
    const categories = {
        accident: [
            {
                value: "103.1",
                label: "Un accident avec un véhicule"
            },
            {
                value: "103.2",
                label: "Un accident avec plusieurs véhicules"
            },
            {
                value: "103.3",
                label: "Un accident avec un piéton/vélo "
            },
            {
                value: "104.1",
                label: "Un accident avec un délit de fuite"
            },
            {
                value: "104.2",
                label: "Un accident seul"
            },
            {
                value: "104.3",
                label: "Un accident en stationnement"
            },
            {
                value: "104.4",
                label: "Un choc avec un animal"
            }
        ],
        vol: [
            {
                value: "10.0",
                label: "Un vol"
            },
            {
                value: "105.1",
                label: "Une tentative de vol"
            },
            {
                value: "9.0",
                label: "Un vol (véhicule retrouvé)"
            },
            {
                value: "105.2",
                label: "Un vol de pièces et accessoires"
            }
        ],
        autres: [
            {
                value: "104.5",
                label: "Un acte de vandalisme"
            },
            {
                value: "18.0",
                label: "Un incendie"
            },
            {
                value: "19.0",
                label: "Un évènement climatique"
            }
        ]
    }

    return categories[type_sinistre] || []
}
