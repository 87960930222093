import {API_ERROR, API_SUCCESS, apiRequest} from "../../../actions/core/api/api.actions";
import {setError, setErrorResponse, setLoader} from "../../../actions/app/ui/ui.actions";
import {setNotification} from "../../../actions/core/notifications/notifications.actions";
import {
    FETCH_PARRAINAGES,
    POST_PARRAINAGES,
    setParrainages,
    PARRAINAGES
} from "../../../actions/app/parrainages/parrainages.actions";
import _ from "lodash";


export const parrainagesMiddleware = () => next => action => {
    next(action)

    switch (action.type){
        case FETCH_PARRAINAGES:
            next([
                apiRequest({body: null, method: 'GET', url: action.payload.data, entity: PARRAINAGES}),
                setLoader({state: true, entity: PARRAINAGES})
            ])
            break

        case `${PARRAINAGES} ${API_SUCCESS}`:
            next([
                setParrainages({data: action.payload.data.data}),
                setError({state: false, entity: PARRAINAGES}),
                setLoader({state: false, entity: PARRAINAGES})
            ])
            break

        case `${PARRAINAGES} ${API_ERROR}`:
            next([
                setError({state: true, entity: PARRAINAGES}),
                setErrorResponse({entity: PARRAINAGES, data: action?.payload?.data?.response?.data}),
                setLoader({state: false, entity: PARRAINAGES})
            ])
            break
        
        case POST_PARRAINAGES:
            let nomForm = `${POST_PARRAINAGES}-${action.payload.body.index}`

            next([
                apiRequest({
                    body: (action.payload.body),
                    method: 'POST',
                    url: action.payload.data,
                    entity: POST_PARRAINAGES,
                    otherData: {
                        nomForm,
                        phone: action.payload.body.phone
                    }
                }),
                setLoader({state: true, entity: nomForm})
            ])
            break

        case `${POST_PARRAINAGES} ${API_SUCCESS}`:
            let isPhone = !_.isEmpty(action.payload.meta.otherData.phone)
            next([
                setNotification({entity: POST_PARRAINAGES,
                    title: 'Félicitations !',
                    icon: 'success',
                    html: `Parrainage envoyé ! Votre filleul va recevoir par ${isPhone ? 'SMS' : 'email'} la proposition de parrainage`,
                    confirmButtonText: 'J\'ai compris'
                }),
                setError({state: false, entity: action.payload.meta.otherData.nomForm}),
                setLoader({state: false, entity: action.payload.meta.otherData.nomForm}),
            ])
            break

        case `${POST_PARRAINAGES} ${API_ERROR}`:
            let message;

            try {
                const encoded = escape(action.payload.data.response.data?.message);
                const decoded = decodeURIComponent(encoded);

                if (action.payload.data.response.data?.message !== decoded) {
                    message = decoded;
                }
            } catch (e) {
                message = action.payload.data.response.data?.message ?? '';
            }

            next([
                setNotification({
                    entity: POST_PARRAINAGES,
                    title: 'Attention !',
                    icon: 'error',
                    html: message,
                    confirmButtonText: 'J\'ai compris'
                }),
                setError({state: true, entity: action.payload.meta.otherData.nomForm}),
                setLoader({state: false, entity: action.payload.meta.otherData.nomForm})
            ])
            break

        default:
            break
    }
    return null
}
