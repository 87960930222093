import React, {useEffect, useState} from 'react';
import './situation_contrat.scss';
import moment from "moment";
import {Link} from "react-router-dom";
import _ from "lodash";
import axios from "axios";
import {localStorageAMO} from "../../../../../../redux/selectors/localStorage/localStorage.js";
import APILink from "../../../../../Commun/APILink/APILink.jsx";


const SituationContrat = (props) => {
    const {isFlotte, immatriculation, query, contract, isWakam, isPrelevement, id_contrat, souscripteur, num_client, num_police, crm, isNVEI, isVAE, date_souscription, echeance, fractionnement, statut_contrat, statut_code_contrat, avantage} = props
    const [quittances, setQuittances] = useState([])

    useEffect(() => {
        const queryReceipts = _.replace(query, '{id}', contract.id)

        axios({
            url: queryReceipts,
            method: 'get',
            headers: {
                authorization: localStorageAMO.token
            }
        }).then(response => {
            setQuittances(response.data.data)
        }).catch(error => {
            setQuittances([])
        })
    }, [contract])

    return (
        <div className={'col-12 bloc-situation-contrat mt-5 mb-5'}>
            <div className={'content position-relative'}>
                <div className={`badge-title badge bg-${statut_code_contrat === "1" ? "success" : statut_code_contrat === "2" ? "warning" : "danger"} m-0 fw-bold`}>
                    Situation du contrat
                    <span className={'d-none d-sm-inline-block mx-2'}>-</span>
                    <span className={'d-block d-sm-inline-block text-start mt-2 mt-sm-0'}>{statut_contrat}</span>
                </div>

                <p><strong>Souscripteur :</strong> {souscripteur}</p>
                <p><strong>N° client :</strong> {num_client}</p>
                <p><strong>Contrat N° :</strong> {num_police}</p>
                { !isNVEI && !isVAE && !isWakam ? <p><strong>Bonus en cours :</strong> {crm}</p> : '' }
                <p><strong>Date de souscription :</strong> {moment(date_souscription).format('L')}</p>
                <p><strong>Echéance principale :</strong> {echeance}</p>
                <p><strong>Fractionnement :</strong> {fractionnement} { !isPrelevement && statut_code_contrat === "1" && <Link to={`/mes-contrats/mon-contrat/${id_contrat}/je-passe-en-prelevement`} className={'float-lg-end d-block text-center mt-3 mt-lg-0'}>Modifier</Link> }</p>
                {avantage && <p className={'mb-0'}><strong>Avantage :</strong> {avantage}</p>}
                {
                    !isFlotte && <>
                        {!_.isEmpty(immatriculation) && quittances.length > 0 && !_.isEmpty(quittances[0].memo_link) && <div className="text-center">
                            <APILink className={"btn btn-primary max-rounded d-block d-lg-inline-block m-3"} href={quittances[0].memo_link}>
                                Télécharger mon Mémo Véhicule Assuré
                            </APILink>
                        </div>}

                        { quittances.length > 0 && !_.isEmpty(quittances[0].attestation_assurance_link) && <div className="text-center mt-3">
                            <APILink className={"btn btn-primary max-rounded d-block d-lg-inline-block m-3"} href={quittances[0].attestation_assurance_link}>
                                Télécharger mon Attestation assurance
                            </APILink>
                        </div>}
                    </>
                }
            </div>
        </div>
    );
};


export default SituationContrat;