import {POST_CUSTOMER_RESET_PASSWORD} from '../../../../actions/app/customer/customer.actions'
import {API_ERROR, API_SUCCESS, apiRequest} from '../../../../actions/core/api/api.actions'
import {setError, setLoader} from '../../../../actions/app/ui/ui.actions'
import {setNotification} from '../../../../actions/core/notifications/notifications.actions'

import {LOGIN} from "../../../../actions/app/login/login.actions";
import {API_END_POINT, APP_CLIENT_ID, APP_GRANT_TYPE, APP_PROVIDER, HOST_MB} from "../../../../../constants/constants";


export const customerResetpasswordMiddleware = () => next => action => {
    next(action)

    const { payload } = action

    switch (action.type){

        case POST_CUSTOMER_RESET_PASSWORD:
            next([
                apiRequest({body: (payload.body), method: 'POST', url: payload.data, entity: POST_CUSTOMER_RESET_PASSWORD, otherData: ({username: payload.body.email, password: payload.body.password, owner_belair_id: HOST_MB, grant_type: APP_GRANT_TYPE, client_id: APP_CLIENT_ID, provider: APP_PROVIDER, scope: '*'})}),
                setLoader({state: true, entity: POST_CUSTOMER_RESET_PASSWORD})
            ])
            break

        case `${POST_CUSTOMER_RESET_PASSWORD} ${API_SUCCESS}`:
            next([
                setError({state: false, entity: POST_CUSTOMER_RESET_PASSWORD}),
                setLoader({state: false, entity: POST_CUSTOMER_RESET_PASSWORD}),
                apiRequest({body: payload.meta.otherData, method: 'POST', url: API_END_POINT, entity: LOGIN}),
                setLoader({state: true, entity: LOGIN})
            ])
            break

        case `${POST_CUSTOMER_RESET_PASSWORD} ${API_ERROR}`:
            next([
                setError({state: true, entity: POST_CUSTOMER_RESET_PASSWORD}),
                setLoader({state: false, entity: POST_CUSTOMER_RESET_PASSWORD}),
                setNotification({
                    entity: POST_CUSTOMER_RESET_PASSWORD,
                    html: '<p>Une erreur est survenue : ' + payload.data.response.data?.message ?? '' + '</p>',
                    icon: 'error',
                    title: 'Attention !',
                    confirmButtonText: 'J\'ai compris'
                })
            ])
            break

        default:
            break
    }
    return null
}
