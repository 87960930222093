import {SET_FLOTTE_DETAIL} from '../../actions/app/flotte/flotte.actions'

export const flotteDetailReducer = (flotte_detail = {}, action = {}) => {
    const {payload} = action

    if (action.type === SET_FLOTTE_DETAIL) {
        return payload
    } else {
        return flotte_detail
    }
}
