import React from 'react';
import InfoClientContainer from "./InfoClient/InfoClientContainer";
import Actualites from "./Actualites/Actualites";
import FaqContainer from "./FAQ/FaqContainer";
import Contact from "./Contact/Contact";
import SituationContratContainer from "./SituationContrat/SituationContratContainer";
import LabelExcellence from "./LabelExcellence/LabelExcellence";
import {HOST_MB} from "../../../../../constants/constants";
import ParrainagesFAQContainer from "./ParrainagesFAQ/ParrainagesFAQContainer";
import HivernageContainer from "./Hivernage/HivernageContainer.js";
import ContactCourtierContainer from './ContactCourtier/ContactCourtierContainer';

const Sidebar = (props) => {
    const { first, second, third, contract, apporteur1 } = props

    switch (first) {
        case 'mes-contrats':
        case 'mes-sinistres':
            switch (second) {
                case 'mon-contrat':
                    return (
                        <div>
                            <SituationContratContainer/>
                            { HOST_MB === 43397 && <HivernageContainer/>}
                            { HOST_MB === 43397 && <LabelExcellence/>}
                        </div>
                    );
                default:
                    return (
                        <div>
                            <InfoClientContainer />
                            {HOST_MB === 43397 && <LabelExcellence />}
                        </div>
                    );
            }
        case 'mes-parrainages':
            return (
                <div>
                    <ParrainagesFAQContainer />
                </div>
            );
        case 'mes-questions':
            return (
                <div>
                    {HOST_MB === 43397 && <Actualites />}
                    {HOST_MB === 43397 && <LabelExcellence />}
                </div>
            );
        case 'mes-avantages':
        case 'mes-actualites':
            return (
                <div>
                    {HOST_MB === 43397 && <FaqContainer />}
                    {HOST_MB === 43397 && <LabelExcellence />}
                </div>
            );
        case 'mentions-legales':
        case 'gestion-des-cookies':
            return (
                <div>
                    {HOST_MB === 43397 && <FaqContainer />}
                    {HOST_MB === 43397 && <Contact />}
                    {HOST_MB === 43397 && <LabelExcellence />}
                </div>
            );
        case 'mon-profil':
            return (
                <div>
                    <InfoClientContainer />
                    {HOST_MB === 43397 && <Actualites />}
                    {HOST_MB === 43397 && <LabelExcellence />}
                </div>
            );
        default:
            return (
                <div>
                    {HOST_MB !== 43397 && apporteur1 && <ContactCourtierContainer />}
                    {HOST_MB === 43397 && <FaqContainer />}
                    {HOST_MB === 43397 && <Actualites />}
                    {HOST_MB === 43397 && <LabelExcellence />}
                </div>
            );
    }
};

export default Sidebar;
